.dropzone {
    text-align: center;
    padding: 30px;
    border: 3px dashed #eeeeee;
    background-color: #fafafa;
    color: #bdbdbd;
    cursor: pointer;
    margin-bottom: 20px;
}

.selected-file-wrapper {
    text-align: center;
}

.selected-file {
    color: #000;
    font-weight: bold;
}

.dash-separator {
    width: 100%;
    height: 1px;
    display: block;
    border-bottom: 2px dotted #a0aebd
}